import * as React from 'react'
import { Button, Seo } from '../components'
import { GlobalStyles } from '../misc/globalStyles'
import { ROUTES } from '../misc/constants'
import { TextPagesWrapper } from '../components/layoutComponents'

const NotFoundPage = () => {
  return (
    <TextPagesWrapper>
      <Seo title="#VentureMeetups | Not found" />
      <GlobalStyles />
      <h1>Page not found</h1>
      <p>
        Sorry{" "}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{" "}
        we couldn’t find what you were looking for.
        <br />
        {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            Try creating a page in <code>src/pages/</code>.
            <br />
          </>
        ) : null}
        <br />
        <Button isLink url={ROUTES.HOME}>Go home</Button>
      </p>
    </TextPagesWrapper>
  )
}

export default NotFoundPage
